import { useState, useEffect } from "react";
import { lightTheme } from "./theme/lightTheme";
import { BrowserRouter as Router } from "react-router-dom";
import {
  Box,
  CircularProgress,
  createTheme,
  CssBaseline,
  responsiveFontSizes,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from "@mui/material";
import Main from "./layouts/Main";
import Header from "./containers/Header";
import KeycloakService from "./keycloak";
import Unauthorized from "./layouts/Unauthorized";

function App() {
  let theme: Theme = createTheme(lightTheme);
  theme = responsiveFontSizes(theme);

  const [isValidUser, setValidUser] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const validateUser = async () => {
      try {
        setValidUser(await KeycloakService.IsValidUser());
      } finally {
        setLoading(false);
      }
    };
    validateUser();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Router>
          <CssBaseline />
          <Header>
            {isLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
              >
                <CircularProgress size={80} />
              </Box>
            ) : isValidUser ? (
              <Main />
            ) : (
              <Unauthorized />
            )}
          </Header>
        </Router>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
