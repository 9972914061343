import styled from "styled-components";

export const FlagContainer = styled.span`
  display: flex;
  align-items: center;
`;

export const Flag = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 4px;
`;
