import { Skeleton, Box } from "@mui/material";
import { ReactElement } from "react";

const LoadingScreen = (): ReactElement => (
  <>
    <Box display="flex" width="100%" py={4}>
      <Skeleton variant="text" width="100%" height={60} />
    </Box>
    <Box>
      <Skeleton variant="rectangular" width="100%" height={300} />
    </Box>
  </>
);

export default LoadingScreen;
