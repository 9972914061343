import API from "services/api";
import KeycloakService from "keycloak";

const PREFIX_API = "worked-hours";

const getAllEmployees = async (): Promise<any> => {
  try {
    const api = await API();
    const res = await api.get(`${PREFIX_API}`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.Token()}`,
      },
    });
    console.log("Employees fetched successfully.");
    return res.data;
  } catch (error) {
    console.error("Error occurred while fetching employees:", error);
    return [];
  }
};

const getAllCostCentres = async (): Promise<any> => {
  try {
    const api = await API();
    const res = await api.get(`${PREFIX_API}/centre`, {
      headers: {
        Authorization: `Bearer ${KeycloakService.Token()}`,
      },
    });
    console.log("Cost Centres fetched successfully.");
    return res.data;
  } catch (error) {
    console.error("Error occurred while fetching cost centres:", error);
    return [];
  }
};

type NoDataProps = {
  userIds: string[],
  costCentreIds: string[],
  emailDomain: string,
  startDateTimestamp: number,
  endDateTimestamp: number,
  onlyApprovedHours: boolean,
  types: string[]
}

const noData = async ({ userIds,
  costCentreIds,
  emailDomain,
  startDateTimestamp,
  endDateTimestamp,
  onlyApprovedHours,
  types }: NoDataProps
): Promise<any> => {
  try {
    const params = new URLSearchParams({
      startDate: startDateTimestamp.toString(),
      endDate: endDateTimestamp.toString(),
      onlyApprovedHours: onlyApprovedHours.toString()
    });

    if (userIds.length > 0) params.append('requestedUserIds', userIds.join(","));
    if (costCentreIds.length > 0) params.append('requestedCostCentreIds', costCentreIds.join(","));
    if (emailDomain) params.append('requestedEmailDomain', emailDomain);
    if (types.length > 0) params.append('requestedEmployeeTypes', types.join(","));

    const api = await API();
    const res = await api.get(
      `${PREFIX_API}/filtered`,
      {
        params,
        headers: {
          Authorization: `Bearer ${KeycloakService.Token()}`,
        },
      }
    );
    console.log("Verified if there is data.");
    return res.data.length === 0;
  } catch (error) {
    console.error("Error occurred verifying if there is data:", error);
  }
};

const refreshWorkedHours = async (): Promise<void> => {
  try {
    const api = await API();
    await api.get(
      `${PREFIX_API}/refresh`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.Token()}`,
        }
      }
    );
    console.log("Hours refreshed successfully.")
  } catch (error) {
    throw new Error(`Could not refresh worked hours: ${error}`);
  }
};

const getLastRefreshedAt = async (): Promise<Date> => {
  try {
    const api = await API();
    const res = await api.get(
      `${PREFIX_API}/last-refreshed`,
      {
        headers: {
          Authorization: `Bearer ${KeycloakService.Token()}`,
        }
      }
    );
    console.log("Time when worked hours were last refreshed fetched successfully.")
    return res.data
  } catch (error) {
    throw new Error(`Could not fetch time worked hours were last refreshed: ${error}`)
  }
};

export { getAllEmployees, getAllCostCentres, noData, refreshWorkedHours, getLastRefreshedAt };
