import {
  enUS as enCore,
  ptBR as ptCore,
  Localization,
} from "@mui/material/locale";

const LS_KEY = "i18nextLng";
const LANGUAGES: { [key: string]: Localization } = {
  en: enCore,
  pt: ptCore,
};

export const getLang = (): string => localStorage.getItem(LS_KEY) || "en";
export const setLang = (lang: string): void =>
  localStorage.setItem(LS_KEY, lang);
export const getCurrentLocale = (): Localization => LANGUAGES[getLang()];
