import { FC } from "react";
import metaLogo from "assets/metaLogo.svg";
import { LogoImage } from "./styles";

interface ILogoProps {
  width?: number;
  height?: number;
}

const Logo: FC<ILogoProps> = ({ width, height }) => (
  <LogoImage
    src={metaLogo}
    alt="Meta"
    width={width || "auto"}
    height={height || "auto"}
  />
);

export default Logo;
