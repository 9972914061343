import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en, pt } from "./locales";
import { getLang } from "./utils/langFunctions";

const fallbackLng = [getLang()];

const resources = {
  en: {
    translation: en,
  },
  pt: {
    translation: pt,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  load: "languageOnly",
  fallbackLng,
  lowerCaseLng: true,
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ["localStorage"],
  },
});

export default i18n;
