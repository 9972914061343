import { FC, useState, useEffect, SetStateAction } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { getLang, setLang } from "translator/utils/langFunctions";
import { useTranslation } from "react-i18next";
import { Flag, FlagContainer } from "./styles";
import FlagBrazil from "assets/flag-brazil.svg";
import FlagCanada from "assets/flag-canada.svg";

const ToggleButtonGroupStyles = { display: "block", backgroundColor: "white" };

const locales = ["en-ca", "pt-br"] as const;

const LanguageSelector: FC = () => {
  const [language, setLanguage] = useState(getLang());
  const { i18n } = useTranslation();

  const handleLanguageChange = (value: SetStateAction<string>) => {
    try {
      setLanguage(value);
      setLang(`${value}`);
      i18n.changeLanguage(`${value}`);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  useEffect(() => {
    const storedLanguage = getLang();
    i18n.changeLanguage(`${storedLanguage}`);
  }, [i18n]);

  return (
    <ToggleButtonGroup value={language} exclusive sx={ToggleButtonGroupStyles}>
      {locales.map((localeItem) => (
        <ToggleButton
          key={localeItem}
          value={localeItem}
          onClick={() => handleLanguageChange(localeItem)}
        >
          {localeItem === "pt-br" && (
            <FlagContainer>
              <Flag src={FlagBrazil} />
              PT
            </FlagContainer>
          )}
          {localeItem === "en-ca" && (
            <FlagContainer>
              <Flag src={FlagCanada} />
              EN
            </FlagContainer>
          )}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default LanguageSelector;
