const dateTimeConverter = (dateTime: Date) => {
    const date = addZeroBeforeSingleDigitValue(new Date(dateTime).getDate());
    const month = addZeroBeforeSingleDigitValue(new Date(dateTime).getMonth() + 1);
    const hours = addZeroBeforeSingleDigitValue(new Date(dateTime).getHours());
    const minutes = addZeroBeforeSingleDigitValue(new Date(dateTime).getMinutes());

    return `${date}/${month} ${hours}:${minutes}`;
};

const addZeroBeforeSingleDigitValue = (value: number): string => {
    if (value < 10) {
        return `0${value}`;
    }
    return value.toString();
};

export default dateTimeConverter;