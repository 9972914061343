import React from "react";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface UnauthorizedProps { }

const Unauthorized: React.FC<UnauthorizedProps> = () => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      marginTop="-104px"
    >
      <Typography variant="h5" align="center" gutterBottom>
        {t("Unauthorized")}
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {t("LoginRequired")}
      </Typography>
    </Box>
  );
};

export default Unauthorized;
