import Keycloak from "keycloak-js";
import axios from "axios";

const keycloak = new Keycloak({
  realm: process.env.REACT_APP_KEYCLOAK_REALM || "",
  url: process.env.REACT_APP_KEYCLOAK_URL || "",
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || "",
});

const IsValidUser = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_KEYCLOAK_ROLES_API}`,
      {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      }
    );
    if (
      response.data.roles.includes(
        `${process.env.REACT_APP_KEYCLOAK_VALID_ROLE}`
      )
    ) {
      console.log("User validation successful.");
      return true;
    } else {
      console.log("User validation failed. Role not found.");
      return false;
    }
  } catch (error) {
    console.error("Error occurred while acquiring roles:", error);
    return false;
  }
};

const GetToken = () => {
  return keycloak.token;
};

const Login = (onAuthenticatedCallback: Function) => {
  keycloak
    .init({ onLoad: "login-required", checkLoginIframe: false })
    .then(function (authenticated) {
      if (authenticated) {
        console.log("User authentication successful.");
        onAuthenticatedCallback();
      } else console.log("User authentication failed.");
    });
};

const Logout = () => {
  keycloak.logout();
};

keycloak.onTokenExpired = () => {
  keycloak.updateToken(5);
};

const KeycloakService = {
  CallLogin: Login,
  CallLogout: Logout,
  Token: GetToken,
  IsValidUser: IsValidUser,
};

export default KeycloakService;
