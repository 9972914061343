import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  CircularProgress
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ReceiptIcon from "@mui/icons-material/Receipt";
import WorkIcon from '@mui/icons-material/Work';
import BusinessIcon from '@mui/icons-material/Business';
import Logo from "components/Logo";
import { useTranslation } from "react-i18next";
import LanguageSelector from "containers/LanguageSelector";
import KeycloakService from "keycloak";
import { AttachMoney, Calculate, People, Refresh } from "@mui/icons-material";
import { getLastRefreshedAt, refreshWorkedHours } from "services/monthSummaryServices";
import SuccessAndErrorFeedback from "components/SuccessAndErrorFeedback/SuccessAndErrorFeedback";
import dateTimeConverter from "converters/dateTimeConverter";

const drawerWidth = 272;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<MuiAppBarProps & { open?: boolean }>(({ theme, open }) => ({
  marginLeft: open ? `${drawerWidth}px` : 0,
  width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Overlay = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  zIndex: 1,
});

interface HeaderProps {
  children?: ReactNode;
}

const Header: React.FC<HeaderProps> = ({ children }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [lastRefreshedAt, setLastRefreshedAt] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [updateLastRefreshed, setUpdateLastRefreshed] = useState<boolean>(false);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  const handleLogout = () => KeycloakService.CallLogout();

  const handleWorkedHoursRefresh = async () => {
    try {
      setLoading(true)
      await refreshWorkedHours();
      setIsSuccess(true);
      setSnackbarMessage("RefreshSuccessful");
      setUpdateLastRefreshed(!updateLastRefreshed);
    }
    catch {
      setIsError(true)
      setSnackbarMessage("RefreshFailed")
    }
    finally {
      setLoading(false);
    }
  };

  const handleGetLastRefreshedAt = async (): Promise<void> => {
    var time = await getLastRefreshedAt();
    setLastRefreshedAt(time);
  };

  useEffect(() => {
    handleGetLastRefreshedAt();
  }, [updateLastRefreshed]);

  return (
    <Box>
      <AppBar open={open} style={{ backgroundColor: "#122870" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, display: open ? "none" : "inline-flex" }}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
          <Logo />
          <Box
            sx={{
              marginLeft: "auto",
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            {!open && <>
              <SuccessAndErrorFeedback
                isSuccess={isSuccess}
                setIsSuccess={setIsSuccess}
                isError={isError}
                setIsError={setIsError}
                snackbarMessage={snackbarMessage}
              />
              {loading ?
                <CircularProgress
                  color="inherit"
                  size={16}
                  sx={{ mr: "16px" }}
                /> :
                <IconButton
                  color="inherit"
                  onClick={handleWorkedHoursRefresh}
                  sx={{ height: "48px" }}
                >
                  <Refresh />
                </IconButton>
              }
              {lastRefreshedAt &&
                <Typography
                  sx={{ mr: 2 }}>
                  {`${t("LastRefreshed")} ${dateTimeConverter(lastRefreshedAt)}`}
                </Typography>}
            </>}
            <LanguageSelector />
            <Button
              variant="contained"
              sx={{
                ml: 1,
                height: "48px",
                backgroundColor: "#FFFFFF",
                color: "#122870",
              }}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {[
            { text: t("Timesheet"), path: "/timesheet", icon: <InboxIcon /> },
            { text: t("Reminder"), path: "/reminder", icon: <MailIcon /> },
            { text: t("Employees"), path: "/employees", icon: <People /> },
            { text: t("InvoiceRequest"), path: "/invoice", icon: <ReceiptIcon /> },
            { text: t("Operations"), path: "/operations", icon: <WorkIcon /> },
            { text: t("AccountingClosing"), path: "/accounting-closing", icon: <Calculate /> },
            { text: t("CostCentres"), path: "/cost-centres", icon: <AttachMoney /> },
            { text: t("Clients"), path: "/clients", icon: <BusinessIcon /> }
          ].map((item) => (
            <ListItem
              key={item.text}
              disablePadding
              onClick={() => navigate(item.path)}
            >
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        {open && <Overlay onClick={handleDrawerClose} />}
        <div style={{ height: "80px" }}></div>
        {children}
      </Main>
    </Box>
  );
};

export default Header;
