import { FC } from "react";
import { Container } from "@mui/material";
import RouteList from "routes/RouteList";

interface IMainContainerProps {
  element: JSX.Element;
}

const MainContainer: FC<IMainContainerProps> = ({ element }) => {
  return (
    <Container
      component="main"
      disableGutters
      sx={{
        paddingBottom: "136px",
        minHeight: "100vh",
        overflow: "hidden",
        display: "block",
        position: "relative",
      }}
    >
      <Container fixed>{element}</Container>
    </Container>
  );
};

const Main: FC = () => (
  <>
    <MainContainer element={<RouteList />} />
  </>
);

export default Main;
