import { createTheme, Theme } from '@mui/material'

// define light theme colors
export const lightTheme: Theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#F8F8F8',
    },
    primary: {
      main: '#0056A8',
    },
    secondary: {
      main: '#FFFFFF',
    },
    text: {
      primary: '#303030',
      secondary: '#C1C1C1',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          height: '100%',
        },
      },
    },
  },
})
