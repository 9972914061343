import { Alert, AlertTitle, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";

const SuccessAndErrorFeedback: React.FC<ISuccessAndErrorFeedbackProps> = ({
    isSuccess,
    setIsSuccess,
    isError,
    setIsError,
    snackbarMessage
}): JSX.Element => {
    const { t } = useTranslation();

    return (<>
        <Snackbar
            open={isError}
            autoHideDuration={5000}
            onClose={() => setIsError(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
            <Alert severity="error">
                <AlertTitle>{t("Error")}</AlertTitle>
                <span>{t(snackbarMessage)}</span>
            </Alert>
        </Snackbar>
        <Snackbar
            open={isSuccess}
            autoHideDuration={5000}
            onClose={() => setIsSuccess(false)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
            <Alert severity="success" sx={{ width: "100%" }}>
                {t(snackbarMessage)}
            </Alert>
        </Snackbar>
    </>);
}

interface ISuccessAndErrorFeedbackProps {
    isSuccess: boolean,
    setIsSuccess: (param: boolean) => void,
    isError: boolean,
    setIsError: (param: boolean) => void,
    snackbarMessage: string
}

export default SuccessAndErrorFeedback;