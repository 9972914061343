import { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LoadingScreen from "containers/LoadingScreen";

const Timesheet = lazy(() => import("pages/Timesheet"));
const EmailReminder = lazy(() => import("pages/Reminder"));
const EmailInvoiceRequest = lazy(() => import("pages/Invoice"));
const Employees = lazy(() => import("pages/Employees"));
const EmployeeDetails = lazy(() => import("pages/EmployeeDetails"));
const Operations = lazy(() => import("pages/Operations"));
const AccountingClosing = lazy(() => import("pages/AccountingClosing"));
const CostCentres = lazy(() => import("pages/CostCentres"));
const Clients = lazy(() => import("pages/Clients"));

const RouteList = (): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Routes>
      <Route path="/timesheet" element={<Timesheet />} />
      <Route path="/reminder" element={<EmailReminder />} />
      <Route path="/invoice" element={<EmailInvoiceRequest />} />
      <Route path="/employees" element={<Employees />} />
      <Route path="/employee-details/:email" element={<EmployeeDetails />} />
      <Route path="*" element={<Navigate to={`/timesheet`} />} />
      <Route path="/operations" element={<Operations />} />
      <Route path="/accounting-closing" element={<AccountingClosing />} />
      <Route path="/cost-centres" element={<CostCentres />} />
      <Route path="/clients" element={<Clients />} />
    </Routes>
  </Suspense>
);

export default RouteList;
